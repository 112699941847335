import React, { useMemo, useState } from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import ItemSectionHeadV2 from '@/v2/storyblok/items/ItemSectionHeadV2'
import ItemIconButtonV2 from '../items/ItemIconButtonV2'
import Icon from '@/components/Icon'

type Props = {
  blok: SbBlokData & ISbSectionInfoTextSliderV2
}

const SectionInfoTextSliderV2 = ({ blok }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selected = useMemo(() => blok.items[selectedIndex], [blok.items, selectedIndex])

  const nextItem = () => {
    setSelectedIndex((prevSelected) => (prevSelected < blok.items.length - 1 ? prevSelected + 1 : 0))
  }

  const prevItem = () => {
    setSelectedIndex((prevSelected) => (prevSelected > 0 ? prevSelected - 1 : blok.items.length - 1))
  }

  const backgroundClass = useMemo(() => {
    switch (blok.background) {
      case 'beige': {
        return 'bg-v2-background-beige'
      }
      case 'green-dark': {
        return 'bg-v2-background-green-dark'
      }
      default: {
        return 'bg-v2-background-blue'
      }
    }
  }, [blok.background])

  const textClass = useMemo(() => {
    switch (blok.background) {
      case 'green-dark': {
        return 'white'
      }
      default: {
        return 'v2-text-primary'
      }
    }
  }, [blok.background])

  return (
    <div className="container-v2 mx-auto grid gap-16 px-4 py-16 sm:px-8 lg:py-24" {...storyblokEditable(blok)}>
      {blok.head && blok.head[0] && <ItemSectionHeadV2 blok={blok.head[0]} />}
      <div className="grid gap-4">
        <div className="grid overflow-hidden rounded-5xl border border-v2-foreground-static-tertiary sm:grid-cols-2">
          <div className="hidden p-20 sm:block sm:p-12 lg:p-20">
            {blok.items.map((item, index) => (
              <div
                key={index}
                onClick={() => setSelectedIndex(index)}
                className={`flex cursor-pointer items-center justify-between rounded-xl p-6 ${
                  index === selectedIndex ? backgroundClass : ''
                }`}
              >
                <div>
                  <span
                    className={`heading-6 select-none text-lg text-${index === selectedIndex ? textClass : 'v2-text-primary'}`}
                  >
                    {item.title}
                  </span>
                </div>
                <Icon
                  name="chevron-right-select"
                  className={`h-6 w-6 shrink-0 [&_path]:!fill-${index === selectedIndex ? textClass : 'v2-text-primary'}`}
                />
              </div>
            ))}
          </div>
          <div className="p-7 text-left font-bold sm:hidden sm:p-20 md:p-12 lg:p-20">
            <div className="flex items-center justify-between p-6">
              <p className="heading-5 select-none text-v2-text-primary">{selected.title}</p>
            </div>
          </div>
          <div
            className={`text-default flex items-center justify-center ${backgroundClass} p-7 text-v2-text-secondary lg:p-20`}
          >
            {selected && <div className={`body-1 whitespace-pre-line p-6 text-${textClass}`}>{selected.text}</div>}
          </div>
        </div>
        <div className="flex gap-3 sm:hidden">
          <ItemIconButtonV2 ariaLabel="Slide Prev" icon="chevron-left-v2" onClick={prevItem} />
          <ItemIconButtonV2 ariaLabel="Slide Next" icon="chevron-right-v2" onClick={nextItem} />
        </div>
      </div>
    </div>
  )
}

export default SectionInfoTextSliderV2
